const ROUTES = {
  // un-protected routes
  DOWNLOAD: "/download",

  // Installer routes
  UPDATE_DEVICE: "/inst/:deviceId/update",

  // general user routes
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  MEDIA: "/media/:linkId",
  PROJECTS: "/projects",
  PROJECT_JOIN: "/projects/join/:code",
  PROJECT_NEW: "/projects/new",
  PROFILE: "/profile",
  NEWS: "/news",

  // admin routes
  // RECOVER: "/recover",
  REMOTE_MAIN: "/remote",
  REMOTE_DEVICE: "/remote/:deviceId",

  // project page tabs
  PROJECT_OVERVIEW: "/project/:projectId/overview",
  PROJECT_MEDIA: "/project/:projectId/media",
  PROJECT_MEDIA_EDIT_TAB: "/project/:projectId/media/edit/:tab",
  PROJECT_MEDIA_PREVIEW: "/project/:projectId/media/preview/:draftId",
  PROJECT_MEDIA_MESSAGES: "/project/:projectId/media/messages",
  PROJECT_DEVICES: "/project/:projectId/devices",
  PROJECT_DEVICE_SINGLE: "/project/:projectId/device/:deviceId",
  PROJECT_DEVICE_TAB: "/project/:projectId/device/:deviceId/:tab",
  PROJECT_ANALYTICS: "/project/:projectId/analytics",
  PROJECT_TEAM: "/project/:projectId/team",
  PROJECT_TEAM_MANAGE: "/project/:projectId/team/manage",
  PROJECT_TEAM_SHARE: "/project/:projectId/team/share",
};

export default ROUTES;
