import React, {useState} from "react";
import {Chip, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router-dom";

import {
  useDeviceProjectId,
  useDeviceStoreInfo,
} from "../services/DeviceService";
import {useProjectInfo} from "../services/ProjectService";
import {updateUrl} from "../services/UiService";

import MyAppBar from "../ui/MyAppBar";
import ScreenCard from "../ui/cards/ScreenCard";

import {getDeviceRemoteRoute} from "../route";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
}));

const RemotePage = () => {
  const params = useParams();
  const [deviceId, setDeviceId] = useState(params?.deviceId || "");
  const projectId = useDeviceProjectId(deviceId);
  const info = useProjectInfo({projectId});
  const storeInfo = useDeviceStoreInfo(deviceId);
  const classes = useStyles();

  const onInputChange = (e) => {
    const input = e.target.value;
    setDeviceId(input);
    updateUrl(getDeviceRemoteRoute(input));
  };

  const deviceIdInput = deviceId?.length >= 15 ? deviceId : null;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MyAppBar
        config={{
          title: info && `${info?.brand}: ${info?.name}`,
        }}
      />
      <div
        style={{
          display: "flex",
          margin: "16px 32px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          label={"Device ID"}
          value={deviceId}
          onChange={onInputChange}
        />
        <div
          style={{
            flexGrow: 1,
          }}
        >
          {storeInfo && (
            <>
              <Chip className={classes.chip} label={storeInfo?.retailer} />
              <Chip className={classes.chip} label={storeInfo?.storeId} />
              <Chip className={classes.chip} label={storeInfo?.postcode} />
              <Chip className={classes.chip} label={storeInfo?.country} />
            </>
          )}
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          margin: 16,
          marginTop: 0,
        }}
      >
        <ScreenCard deviceId={deviceIdInput} active={true} />
      </div>
    </div>
  );
};

export default RemotePage;
