import React, { useState, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  Card,
  CardHeader,
  Menu,
  MenuItem,
  CardContent,
  Button,
  LinearProgress,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import { useSnackbar } from "notistack";

import { useMobileLayout, useConfirm } from "../hooks/uiHooks";
import { isMediaLinkExpired } from "../services/MediaService";
import {
  useUserProjectPermissions,
  useProjectDraftName,
} from "../services/ProjectService";
import { useCanEditAndDeleteMediaLink } from "../services/PermissionService";
import { copyText } from "../utils/generalUtils";
import { formatLocaleDate } from "../utils/localeUtils";
import { downloadQR } from "../utils/fileUtils";
import { useCurrentUserId } from "../services/UserService";
import * as Permissions from "../utils/permissionUtils";
import { deleteShowroomLink } from "../services/ApiService";
import { ProjectStrings, DefaultStrings } from "../strings";
import { uploadFile, deleteFile } from "services/StorageService";
import { updateShowroomLink } from "services/ApiService";
import { CustomQRCodeDialog } from "./dialogs";

const QR_MYPLAYER_ICON = "/static/media/icon_my_player_v3.ed4eaf7f.png";
const QR_CONFIG = {
  size: 1024,
  includeMargin: true,
  imageSettings: {
    src: QR_MYPLAYER_ICON,
    x: null,
    y: null,
    height: 48,
    width: 48,
    excavate: true,
  },
};

const useStyles = makeStyles((theme) => ({
  root: ({ expired }) => ({
    background: expired
      ? theme.palette.background.paper
      : theme.palette.background.default,
    marginTop: theme.spacing(3),
    "&:first-child": {
      marginTop: 0,
    },
  }),
  header: {
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.text.primary,
  },
  content: ({ mobile }) => ({
    paddingTop: 0,
    display: "flex",
    flexDirection: mobile ? "column" : "row",
  }),
  info: ({ mobile }) => ({
    display: "flex",
    flexDirection: "column",
    wordWrap: "break-word",
    width: "75%",
    marginBottom: mobile ? theme.spacing(1) : 0,
  }),
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  buttonContainer: ({ mobile }) => ({
    display: "flex",
    justifyContent: mobile ? "flex-start" : "flex-end",
    alignItems: "center",
    flex: 1,
    flexWrap: mobile ? "wrap" : "nowrap",
  }),
  button: {
    minWidth: 150,
    background: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
  },
  dates: ({ mobile }) => ({
    display: "flex",
    flexDirection: mobile ? "column" : "row",
  }),
  date: ({ mobile }) => ({
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(4),
    marginBottom: mobile ? theme.spacing(1) : 0,
  }),
  dateTitle: {
    marginRight: theme.spacing(1),
    fontWeight: 500,
  },
  fontSize14: {
    fontSize: 14,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  expired: {
    color: theme.palette.grey[400],
  },
}));

// TODO: this component's UI is a bit too long (complicated) to follow in one component
// break it down to child components depending on the link type
const MediaLink = ({
  link,
  projectId,
  onEdit,
  config: { icon, title, buttonText },
  loading,
}) => {
  const expired = isMediaLinkExpired(link);
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, expired });
  const { enqueueSnackbar } = useSnackbar();
  const qrRef = useRef();
  const [anchor, setAnchor] = useState(null);
  const [showCustomQRDialog, setShowCustomQRDialog] = useState(false);
  const [mediaQRSettings, setMediaQRSettings] = useState(null);
  const confirm = useConfirm();
  const userId = useCurrentUserId();
  const draftName = useProjectDraftName({
    projectId,
    draftName: link?.draftId,
  });

  // permissions control
  const permissions = useUserProjectPermissions({ userId, projectId });
  const canWriteShowroom = Permissions.canWriteShowroom(permissions);
  const canEditAndDeleteLink = useCanEditAndDeleteMediaLink({
    projectId,
    linkCreatorId: link.createdBy,
  });

  const closeMenu = () => setAnchor(null);

  const onDelete = () =>
    confirm({
      message: ProjectStrings.SHOWROOM_LINK_DELETE_CONFIRM.replace(
        "{name}",
        link.name
      ),
    })
      .then(async () => {
        try {
          await deleteShowroomLink({ projectId, linkId: link.linkId });
          enqueueSnackbar(ProjectStrings.SHOWROOM_LINK_DELETED, {
            variant: "success",
          });
        } catch (error) {
          console.warn(error);
          enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
        }
      })
      .catch(() => closeMenu());

  const onCopy = async () => {
    try {
      await copyText(link.url);
      enqueueSnackbar(ProjectStrings.SHOWROOM_LINK_COPIED, { variant: "info" });
    } catch (error) {
      console.warn(error);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  const onOpenCustomQR = async () => {
    const { name, url, qrSetup } = link;
    setMediaQRSettings({ qrSetup, options: [{ url, filename: name }] });
    setShowCustomQRDialog(true);
  };

  const onDownloadQR = () => {
    try {
      const canvas = qrRef.current.firstElementChild;
      const fileName = `${link.name}.png`;
      downloadQR(canvas, fileName);
    } catch (error) {
      console.warn(error);
      enqueueSnackbar(DefaultStrings.ERROR_MSG, { variant: "error" });
    }
  };

  if (!link || typeof expired !== "boolean") return <></>;

  // TODO: instead of switching values in a single component we prefer to switch between different components
  // or make use of props
  const buttonCustomQR = (
    <Button
      className={classes.button}
      disabled={expired}
      variant="contained"
      onClick={onOpenCustomQR}
    >
      {ProjectStrings.SHOWROOM_CUSTOMISE_QR_BUTTON}
    </Button>
  );

  const buttonDefault = (
    <Box mr={1}>
      <Button
        className={classes.button}
        disabled={expired}
        variant="contained"
        onClick={
          link.type === ProjectStrings.SHOWROOM_QR ? onDownloadQR : onCopy
        }
      >
        {buttonText}
      </Button>
    </Box>
  );

  // TODO: this is a quick fix and should be merged with buttonDefault to some extent

  const buttonCopy = (
    <Box mr={1}>
      <Button
        className={classes.button}
        disabled={expired}
        variant="contained"
        onClick={onCopy}
      >
        {ProjectStrings.SHOWROOM_PREVIEW_BUTTON}
      </Button>
    </Box>
  );

  const buttons = (
    <div className={classes.buttonContainer}>
      {link.type === ProjectStrings.SHOWROOM_QR && buttonCopy}
      {buttonDefault}
      {link.type === ProjectStrings.SHOWROOM_QR && buttonCustomQR}
    </div>
  );

  const onEditClick = (link) => {
    closeMenu();
    onEdit(link);
  };

  const onCloseQRHandle = () => {
    setShowCustomQRDialog(false);
    setMediaQRSettings(null);
  };

  const onOkQRHandle = () => {
    setShowCustomQRDialog(false);
  };

  const onUpdateQRHandle = async (qrSettings) => {
    const { qrSetup, image } = qrSettings;
    let data = {
      ...link,
      expiry: link.expiry && link.expiry.toDate(),
      projectId,
      qrSetup,
    };

    const { file, action, ...restImage } = image;
    const imageStoragePath = `projects/${projectId}/mediaLinks/${link.linkId}`;

    if (action === "upload") {
      const url = await uploadFile({ file, path: imageStoragePath });
      delete restImage.action;
      data = {
        ...data,
        qrSetup: { ...qrSetup, image: { ...restImage, url } },
      };
    } else if (action === "remove") {
      const { action, url, fileType, ...restImage } = image;
      await deleteFile({ path: imageStoragePath });
      data = { ...data, qrSetup: { ...qrSetup, image: { ...restImage } } };
    }

    await updateShowroomLink(data);
    setMediaQRSettings(null);
  };

  return (
    <Card className={classes.root}>
      {loading && <LinearProgress />}
      {mediaQRSettings && (
        <CustomQRCodeDialog
          open={showCustomQRDialog}
          title={ProjectStrings.SHOWROOM_CUSTOM_QR_TITLE}
          settings={mediaQRSettings}
          folderName={`${link.name}-media-link-qr`}
          onOk={onOkQRHandle}
          onClose={onCloseQRHandle}
          onUpdate={onUpdateQRHandle}
        />
      )}
      <CardHeader
        className={
          expired ? `${classes.header} ${classes.expired}` : classes.header
        }
        action={
          <IconButton
            disabled={loading}
            className={classes.icon}
            onClick={(e) => setAnchor(e.currentTarget)}
            color="default"
          >
            <MoreIcon />
          </IconButton>
        }
        title={title}
        avatar={icon}
        titleTypographyProps={{ variant: "caption" }}
      />
      <CardContent className={classes.content}>
        <div className={classes.info}>
          <span
            className={
              expired
                ? `${classes.expired} ${classes.title} ${classes.marginBottom}`
                : `${classes.title} ${classes.marginBottom}`
            }
          >
            {link.name}
          </span>
          <div className={classes.dates}>
            {link.expiry && (
              <Box className={classes.fontSize14} mr={1}>
                <Typography variant="caption" className={classes.dateTitle}>
                  {ProjectStrings.SHOWROOM_EXPIRES_LABEL}
                </Typography>
                {expired ? (
                  <Typography variant="caption" className={classes.dateTitle}>
                    {ProjectStrings.SHOWROOM_EXPIRED}
                  </Typography>
                ) : (
                  <span>
                    {link.expiry && formatLocaleDate(link.expiry.toDate())}
                  </span>
                )}
              </Box>
            )}
            <div className={classes.fontSize14}>
              <Typography variant="caption" className={classes.dateTitle}>
                {ProjectStrings.SHOWROOM_CREATED_LABEL}
              </Typography>
              <span>
                {link.created && formatLocaleDate(link.created.toDate())}
              </span>
            </div>
          </div>
          {draftName && (
            <div className={classes.fontSize14}>
              <Typography variant="caption" className={classes.dateTitle}>
                {ProjectStrings.SHOWROOM_DRAFT_LABEL}
              </Typography>
              <span>{draftName}</span>
            </div>
          )}
        </div>
        {buttons}
      </CardContent>
      <Menu
        id="menu"
        anchorEl={anchor}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
        open={anchor !== null}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => onEditClick(link)}
          disabled={!canWriteShowroom || !canEditAndDeleteLink}
        >
          {ProjectStrings.SHOWROOM_LINK_EDIT}
        </MenuItem>
        <MenuItem
          onClick={onDelete}
          disabled={!canWriteShowroom || !canEditAndDeleteLink}
        >
          {ProjectStrings.SHOWROOM_LINK_DELETE}
        </MenuItem>
      </Menu>
      <div ref={qrRef} style={{ display: "none" }}>
        {link.type === ProjectStrings.SHOWROOM_QR && (
          <QRCode
            key={link.linkId}
            size={QR_CONFIG.size}
            includeMargin={QR_CONFIG.includeMargin}
            value={link.url}
            imageSettings={QR_CONFIG.imageSettings}
          />
        )}
      </div>
    </Card>
  );
};

export default MediaLink;
