const STRINGS = {
  PROJECT_JOIN: `{userName} has joined the team`,
  PROJECT_NEW: `{userName} has created the project`,
  PROJECT_LEAVE: `{userName} has left the team`,

  PROJECT_HARDWARE_UPDATE: `{userName} has updated project hardware`,
  PROJECT_NOTE_UPDATE: `{userName} has updated project note`,
  PROJECT_STATUS_UPDATE: `{userName} has updated project status to "{targetName}"`,
  PROJECT_INFO_UPDATE: `{userName} has updated project info`,
  PROJECT_QR_SETUP_UPDATE: `{userName} has updated devices QR settings`,

  PROJECT_MEDIA_UPLOAD: `{userName} has updated the media`,
  PROJECT_MEDIA_APPROVE: `{userName} has approved the media`,
  PROJECT_MEDIA_APPS_UPDATE: `{userName} has updated project apps`,
  PROJECT_MEDIA_RESOLUTION_UPDATE: `{userName} has updated media resolution`,
  PROJECT_MEDIA_LINK_CREATE: `{userName} has created new media link "{targetName}"`,
  PROJECT_MEDIA_LINK_DELETE: `{userName} has deleted media link "{targetName}"`,
  PROJECT_MEDIA_LINK_UPDATE: `{userName} has updated media link "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_TEXT: `{userName}: "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_PHOTO: `{userName} has uploaded a photo: "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_DELETE: `{userName} has deleted a message`,
  PROJECT_MEDIA_DRAFT_CREATE: `{userName} has created media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_UPDATE: `{userName} has updated media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_APPROVE: `{userName} has approved media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_DELETE: `{userName} has deleted media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_PUBLISH: `{userName} has published media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_UPDATE_NAME: `{userName} has updated media draft name "{targetName}"`,

  PROJECT_TEAM_REMOVE_USER: `{userName} has removed {targetName} from the team`,
  PROJECT_TEAM_UPDATE_USER_PERMISSION: `{userName} has updated role for {targetName}`,
  PROJECT_TEAM_CODE_NEW: `{userName} has created new code "{targetName}"`,
  PROJECT_TEAM_CODE_DELETE: `{userName} has deleted code "{targetName}"`,

  PROJECT_ANALYTICS_UPDATE: `{userName} has updated analytics code`,

  DEVICE_REQUEST_ONLINE: `{userName} has requested device to come online`,
  DEVICE_REQUEST_DECOMMISSION: `{userName} has decommissioned device`,
  DEVICE_REQUEST_RECOMMISSION: `{userName} has recommissioned device`,
  DEVICE_UPDATE_STORE: `{userName} has updated device store info`,
  DEVICE_UPDATE_SIM: `{userName} has updated device SIM`,
  DEVICE_UPDATE_NOTE: `{userName} has updated device note`,
  DEVICE_MESSAGE_TEXT: `{userName} "{targetName}"`,
  DEVICE_MESSAGE_PHOTO: `{userName} has uploaded a photo: "{targetName}"`,
  DEVICE_MESSAGE_VIDEO: `{userName} has uploaded a video: "{targetName}"`,
  DEVICE_MESSAGE_DELETE: `{userName} has deleted a message`,
  DEVICE_TRANSFER: `{userName} has requested device to transfer to "{targetName}"`,
  DEVICE_NEW: `New device: {targetName}`,
  DEVICE_UPDATE_TAGS: `{userName} has updated device tags {targetName}`,

  USER_UPDATE_PROFILE: `{userName} has updated profile`,
};

const SELF_STRINGS = {
  PROJECT_JOIN: `You have joined the team`,
  PROJECT_NEW: `You have created the project`,
  PROJECT_LEAVE: `You have left the team`,

  PROJECT_HARDWARE_UPDATE: `You have updated project hardware`,
  PROJECT_NOTE_UPDATE: `You have updated project note`,
  PROJECT_STATUS_UPDATE: `You have updated project status to "{targetName}"`,
  PROJECT_INFO_UPDATE: `You have updated project info`,
  PROJECT_QR_SETUP_UPDATE: `You have updated devices QR settings`,

  PROJECT_MEDIA_UPLOAD: `You have updated the media`,
  PROJECT_MEDIA_APPROVE: `You have approved the media`,
  PROJECT_MEDIA_APPS_UPDATE: `You have updated project apps`,
  PROJECT_MEDIA_RESOLUTION_UPDATE: `You have updated media resolution`,
  PROJECT_MEDIA_LINK_CREATE: `You have created new media link "{targetName}"`,
  PROJECT_MEDIA_LINK_DELETE: `You have deleted media link "{targetName}"`,
  PROJECT_MEDIA_LINK_UPDATE: `You have updated media link "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_TEXT: `You: "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_PHOTO: `You have uploaded a photo: "{targetName}"`,
  PROJECT_MEDIA_MESSAGE_DELETE: `You have deleted a message`,
  PROJECT_MEDIA_DRAFT_CREATE: `You have created media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_UPDATE: `You have updated media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_APPROVE: `You have approved media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_DELETE: `You have deleted media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_PUBLISH: `You have published media draft "{targetName}"`,
  PROJECT_MEDIA_DRAFT_UPDATE_NAME: `You have updated media draft name "{targetName}"`,

  PROJECT_TEAM_REMOVE_USER: `You have removed {targetName} from the team`,
  PROJECT_TEAM_UPDATE_USER_PERMISSION: `You have updated role for {targetName}`,
  PROJECT_TEAM_CODE_NEW: `You have created new code "{targetName}"`,
  PROJECT_TEAM_CODE_DELETE: `You have deleted code "{targetName}"`,

  PROJECT_ANALYTICS_UPDATE: `You have updated analytics code`,

  DEVICE_REQUEST_ONLINE: `You have requested device to come online`,
  DEVICE_REQUEST_DECOMMISSION: `You have decommissioned device`,
  DEVICE_REQUEST_RECOMMISSION: `You have recommissioned device`,
  DEVICE_UPDATE_STORE: `You have updated device store info`,
  DEVICE_UPDATE_SIM: `You have updated device SIM`,
  DEVICE_UPDATE_NOTE: `You have updated device note`,
  DEVICE_MESSAGE_TEXT: `You "{targetName}"`,
  DEVICE_MESSAGE_PHOTO: `You have uploaded a photo: "{targetName}"`,
  DEVICE_MESSAGE_VIDEO: `You have uploaded a video: "{targetName}"`,
  DEVICE_MESSAGE_DELETE: `You have deleted a message`,
  DEVICE_TRANSFER: `You have requested device to transfer to "{targetName}"`,
  DEVICE_NEW: `New device: {targetName}`,
  DEVICE_UPDATE_TAGS: `You have updated device tags`,

  USER_UPDATE_PROFILE: "You have updated your profile",
};

const TARGET_YOU = "you";

export { STRINGS, SELF_STRINGS, TARGET_YOU };
