import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import md5 from "md5";
import { ROUTES } from "../../route";
import { Redirect } from 'react-router-dom'

import MyDialog from "../../ui/MyDialog";
import PasswordField from "../../ui/PasswordField";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MyAppBar from "../../ui/MyAppBar";
import FullScreenMedia from "../../ui/FullScreenMedia";
import { MediaContent, PublicMediaFrame } from "../../ui/media";
import Spinner from "../../ui/Spinner";

import { useMobileLayout, useWindowSize } from "../../hooks/uiHooks";
import { useMediaLink, isMediaLinkExpired } from "../../services/MediaService";
import { addCookieBannerScript } from "../../services/UiService";
import { isLoading } from "../../utils/uiUtils";
import { isProjectDeviceRegistered } from "../../services/ApiService";
import {
  useMediaLinkPostMessage,
  useGoogleAnalyticsUserId,
} from "../../services/ShowroomMessageService";
import IFrameTrackingService from "../../services/IFrameTrackingService";
import { KEYBOARD_KEYS } from "../../utils/projectUtils";
import useMyStoriesV2Service from "../../services/MyStoriesV2Service";

import { getProjectsRoute } from "route";
import { ErrorStrings, DefaultStrings, ProjectStrings } from "../../strings";

const useStyles = makeStyles(() => {
  return {
    mediaRoot: ({ height }) => ({
      width: "100vw",
      height: `${height}px`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }),
    errorText: {
      textAlign: "center",
    },
    mediaMain: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      position: "relative",
      overflowX: "hidden",
      flex: 1,
    },
    mediaRootFixed: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  };
});

const ErrorContent = ({ classes, title, description }) => (
  <div className={classes.mediaRoot}>
    <div className={classes.errorText}>
      <Typography variant="h1" style={{ fontWeight: 500 }}>
        {ErrorStrings.ERROR_PUBLIC_MEDIA_EXPIRED_TITLE}
      </Typography>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h6" style={{ fontWeight: "normal", maxWidth: 500 }}>
        {description}
      </Typography>
    </div>
  </div>
);

const EmbedContent = React.forwardRef(({ classes, mediaLink }, ref) => (
  <div className={classes.mediaRoot}>
    <PublicMediaFrame ref={ref} mediaLink={mediaLink} />
  </div>
));

const QrContent = React.forwardRef(({ classes, mediaLink }, ref) => {
  return mediaLink.autoSize ? (
    // autoSize means input was empty, and use viewport size
    <div className={classes.mediaRoot}>
      <PublicMediaFrame ref={ref} mediaLink={mediaLink} />
    </div>
  ) : (
    // autoSize false means width and height input both given
    <div
      className={classes.mediaRootFixed}
      style={{
        width: `${mediaLink.width}px`,
        height: `${mediaLink.height}px`,
      }}
    >
      <PublicMediaFrame ref={ref} mediaLink={mediaLink} />
    </div>
  );
});

const PreviewContent = ({ classes, mediaLink }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showMediaContent, setShowMediaContent] = useState(false); // ensures not to show mediaContent on initail renders or prior to password-dialog

  const mobile = useMobileLayout();
  const [fullscreen, setFullscreen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
  const history = useHistory();

  useEffect(() => {
    setShowDialog(!!mediaLink.password);
    setShowMediaContent(!mediaLink.password);
  }, [mediaLink.password]);

  const appBarConfig = {
    title: DefaultStrings.TITLE,
  };

  const onFullscreen = () => {
    setFullscreen(true);
  };
  const onClose = () => setFullscreen(false);
  const handleSubmit = () => {
    if (md5(currentPassword) === mediaLink.password) {
      setShowDialog(false);
      setShowMediaContent(true);
    } else {
      setCurrentPasswordErrorText(
        ProjectStrings.SHOWROOM_PASSWORD_ERROR_MESSAGE
      );
    }
  };
  //Dialog
  const configDialog = {
    icon: <LockOpenIcon />,
    title: ProjectStrings.SHOWROOM_PASSWORD_REQUIRED,
    onClose: () => {
      history.push(getProjectsRoute(mediaLink.projectId));
    },
    onOk: handleSubmit,
    disableOk: currentPassword.length === 0,
  };

  const onChangeCurrentPassword = (value) => {
    setCurrentPasswordErrorText("");
    setCurrentPassword(value);
  };

  const keyPressed = (event) => {
    if (event.keyCode === KEYBOARD_KEYS.ENTER) {
      handleSubmit();
    }
  };

  if (showDialog)
    return (
      <MyDialog open={showDialog} config={configDialog}>
        <PasswordField
          name="password"
          label={ProjectStrings.SHOWROOM_PASSWORD_LABEL}
          width="100%"
          value={currentPassword}
          onChange={onChangeCurrentPassword}
          error={currentPasswordErrorText}
          keyPressed={keyPressed}
        />
      </MyDialog>
    );

  if (showMediaContent !== true) return <></>;

  if (fullscreen)
    return (
      <FullScreenMedia onClose={onClose}>
        <PublicMediaFrame mediaLink={mediaLink} />
      </FullScreenMedia>
    );

  return (
    <div className={classes.mediaRoot}>
      <MyAppBar mobile={mobile} config={appBarConfig} />
      <div className={classes.mediaMain}>
        <MediaContent
          mediaLink={mediaLink}
          onFullscreen={onFullscreen}
          fullscreenDisabled={false}
        />
      </div>
    </div>
  );
};

/**
 * MediaPage displays media content in various forms:
 * - Preview: in a MyPlayer-branded page
 * - QR: full-page display with a QR link
 * - Embed: inside an iframe
 */

const MediaPage = () => {
  const { height } = useWindowSize();
  const classes = useStyles({ height });
  const { linkId } = useParams();
  const mediaLink = useMediaLink(linkId);
  const isExpired = isMediaLinkExpired(mediaLink);
  const iframeRef = useRef();
  useMediaLinkPostMessage({ iframeRef, mediaLink });
  useMyStoriesV2Service(iframeRef, mediaLink);
  const gauid = useGoogleAnalyticsUserId();
  const showLoading = isLoading(mediaLink); // loading
  const showError = !mediaLink || isExpired || !!mediaLink?.deleted; // not found, expired or invalid
  // const isRegistered = mediaLink && mediaLink.registered;
  const isDeviceQR = mediaLink && mediaLink.type === ProjectStrings.DEVICE_QR;
  const isShowroomQR = mediaLink && mediaLink.type === ProjectStrings.SHOWROOM_QR;
  // conditions for which type of content to show, otherwise default (preview with app bar)
  const showEmbed =
    mediaLink && mediaLink.type === ProjectStrings.SHOWROOM_EMBED;
  const showQr = isShowroomQR || isDeviceQR;

  const [redirectURL, setRedirectURL] = useState(undefined);


  useEffect(() => {
    if (!mediaLink || !mediaLink.deviceId) return;
    const { deviceId } = mediaLink;
    const fetchData = async () => {
      const rsp = await isProjectDeviceRegistered({ deviceId });
      if (!rsp.result) {
        setRedirectURL(`${ROUTES.UPDATE_DEVICE.replace(':deviceId', deviceId)}`);
      }
    }
    fetchData();
  }, [mediaLink]);

  useEffect(() => {
    if (showEmbed === false) addCookieBannerScript();
  }, [showEmbed]);

  useEffect(() => {
    if (!mediaLink) return;
    if (mediaLink.type === ProjectStrings.SHOWROOM_PREVIEW) return;
    if (!gauid) return;

    // start tracking
    IFrameTrackingService.connect(iframeRef, mediaLink, {
      userId: gauid,
    });
  }, [gauid, mediaLink]);

  if (showLoading) return <Spinner />;

  // go to device registration if media link not registered
  if (redirectURL) {
    const state = { next: window.location.pathname, type: mediaLink.type };
    const pathname = redirectURL;
    return (
      <Redirect
        to={{
          pathname,
          state
        }}
      />)
  }

  if (showError)
    return (
      <ErrorContent
        classes={classes}
        description={ErrorStrings.ERROR_PUBLIC_MEDIA_INVALID_DESC}
        title={ErrorStrings.ERROR_PUBLIC_MEDIA_INVALID_SUBTITLE}
      />
    );

  if (showEmbed)
    return (
      <EmbedContent ref={iframeRef} classes={classes} mediaLink={mediaLink} />
    );

  if (showQr)
    return (
      <QrContent ref={iframeRef} classes={classes} mediaLink={mediaLink} />
    );

  return <PreviewContent classes={classes} mediaLink={mediaLink} />;
};

export default MediaPage;
