import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  getCollectionGroup,
  queryDocuments,
  getDocumentsById,
  useSubCollectionDocument,
  useSelectorWithPath,
  subscribeDocument,
  unsubscribeDocument,
  useCollectionNoStore,
  useDocument,
  useCollection,
  queryCollection,
  getDocument,
} from "./FirestoreService";
import { requestHeartbeat, ccScreencap, ccCommand } from "./ApiService";
import { useProjectDevicesId } from "./ProjectService";
import { isPathExist } from "./StorageService";
import { getLinkUrl } from './MediaService';

import {
  extractKeyValuesFromDict,
  convertArrayToCsv,
  isUndefined,
} from "../utils/generalUtils";
import {
  getLocalTimeString,
  isOnline,
  getLastOnlineDate,
} from "../utils/localeUtils";
import { downloadBlob } from "../utils/fileUtils";
import { convertRawToPlainText } from "../utils/draftJsUtils";
import { getCountryFromLocale } from "utils/localeUtils";

const COLLECTIONS = {
  PROJECT_DEVICES: "projects_and_devices",
  DEVICE_MEDIA_LINKS: "media_links",
  DEVICE: "devices",
  DEVICE_STATUS: "device_status",
  DEVICE_DEPLOYMENT: "device_deployment",
  DEVICE_HEARTBEAT: "device_heartbeats",
  DEVICE_STORE: "stores",
};

const KEYS = {
  DEVICE_ID: "deviceId",
  DEVICE_DECOMMISSIONED: "decommissioned",
  DEVICE_DECOMMISSIONED_AT: "decommissionedAt",
  DEVICE_DECOMMISSIONED_BY: "decommissionedBy",
  DEVICE_STORE_ID: "storeId",
  DEVICE_PLATFORM: "platform",
  DEVICE_STORE_DOC_ID: "storeDocId",
  DEVICE_COUNTRY: "country",
  DEVICE_POSTCODE: "postcode",
  DEVICE_RETAILER_NAME: "retailerName",
  DEVICE_LAST_ONLINE: "updatedAt",
  DEVICE_DEPLOYMENT: "state",
  DEVICE_NOTE: "note",
  DEVICE_SIM: "sim",
  DEVICE_STAGE: "stage",
  DEVICE_LAST_CHECKED_COMPLIANCE: "moment",
  DEVICE_TAGS: "tags",
  DEVICE_CONNECTED_DEVICES: "connectedDevices",
  DEVICE_ADDRESS: "address",
  DEVICE_LINK_ID: "linkId",
  DEVICE_LINK: "link",
  // timestamp / 1000
  DEVICE_TIMESTAMP: "timestamp",

  PROJECT_ID: "projectId",
};

const DOCUMENTS = {
  EXTRA_INFO: "extraInfo",
  REMOTE_INFO: "remoteInfo",
};

const SUBCOLLECTIONS = {
  EXTRA: "extra",
};

const INFO_KEYS = [
  KEYS.DEVICE_ID,
  KEYS.DEVICE_LINK_ID,
  KEYS.DEVICE_STORE_ID,
  KEYS.DEVICE_STORE_DOC_ID,
  KEYS.DEVICE_POSTCODE,
  KEYS.DEVICE_RETAILER_NAME,
  KEYS.DEVICE_DECOMMISSIONED,
  KEYS.DEVICE_COUNTRY,
  KEYS.PROJECT_ID,
  KEYS.DEVICE_TAGS,
];
const STATUS_KEYS = [KEYS.DEVICE_LAST_ONLINE, KEYS.DEVICE_STAGE];
const DEPLOYMENT_KEYS = [
  KEYS.DEVICE_DEPLOYMENT,
  KEYS.DEVICE_LAST_CHECKED_COMPLIANCE,
];
const DECOMMISSION_KEYS = [
  KEYS.DEVICE_DECOMMISSIONED,
  KEYS.DEVICE_DECOMMISSIONED_AT,
  KEYS.DEVICE_DECOMMISSIONED_BY,
];

const LIST_INFO_KEYS = [
  KEYS.DEVICE_PLATFORM,
  KEYS.DEVICE_STORE_ID,
  KEYS.DEVICE_DECOMMISSIONED,
  KEYS.DEVICE_RETAILER_NAME,
  KEYS.DEVICE_COUNTRY,
  KEYS.DEVICE_TAGS,
];
const LIST_STATUS_KEYS = STATUS_KEYS;
const lIST_DEPLOYMENT_KEYS = [KEYS.DEVICE_DEPLOYMENT];

const DEPLOYMENT_STATE = {
  COMPLIANT: "compliant",
  NONCOMPLIANT: "noncompliant",
  ERROR: "error",
};

export const DEVICE_PLATFORMS = {
  ANDROID: "android",
  LINUX: "linux",
  QRID: "qrId",
};


const escape = (x) => (x ? `"${x.replace(/"/g, '""')}"` : "");

const convertTagsToString = (tags) => tags?.toString();

// devices = [{port: 1,productName: ""}]
const connectedDevicesToString = (devices) =>
  devices?.map((d) => `Port ${d.port}: ${d.productName}`).join(", ");

const EXPORT_HEADERS = (isAdmin) =>
  [
    {
      label: "IMEI",
      key: KEYS.DEVICE_ID,
    },
    {
      label: "Store ID",
      key: KEYS.DEVICE_STORE_ID,
      convertFunc: escape,
    },
    {
      label: "Country",
      key: KEYS.DEVICE_COUNTRY,
      convertFunc: (x) => escape(getCountryFromLocale(x)),
    },
    {
      label: "Postcode",
      key: KEYS.DEVICE_POSTCODE,
      convertFunc: escape,
    },
    {
      label: "Address",
      key: KEYS.DEVICE_ADDRESS,
      convertFunc: escape,
    },
    {
      label: "Link",
      key: KEYS.DEVICE_LINK,
      convertFunc: escape,
    },
    {
      label: "Retailer",
      key: KEYS.DEVICE_RETAILER_NAME,
      convertFunc: escape,
    },
    {
      label: "Last Online",
      key: KEYS.DEVICE_LAST_ONLINE,
      convertFunc: (d) => (d ? getLocalTimeString(new Date(d)) : ""), // convert the date time
    },
    {
      label: "Compliant",
      key: KEYS.DEVICE_DEPLOYMENT,
    },
    {
      label: "Note",
      key: KEYS.DEVICE_NOTE,
      convertFunc: (x) => escape(convertRawToPlainText(x)),
    },
    {
      label: "Tags",
      key: KEYS.DEVICE_TAGS,
      convertFunc: (x) => escape(convertTagsToString(x)),
    },
    ...[
      isAdmin && {
        label: "Connected Devices",
        key: KEYS.DEVICE_CONNECTED_DEVICES,
        convertFunc: (x) => escape(connectedDevicesToString(x)),
      },
    ],
  ].filter((i) => !!i);

const ID_KEY = KEYS.DEVICE_ID;

const getDevicesCollection = ({ collection, ids, keys }) =>
  getDocumentsById({ collection, idKey: ID_KEY, ids }).then((docs) =>
    Object.fromEntries(
      docs.map((d) => [d[ID_KEY], extractKeyValuesFromDict(d, keys)])
    )
  );

const getDevicesInfo = (ids) =>
  getDevicesCollection({
    collection: COLLECTIONS.DEVICE,
    ids,
    keys: INFO_KEYS,
  });

const getDevicesStatus = (ids) =>
  getDevicesCollection({
    collection: COLLECTIONS.DEVICE_STATUS,
    ids,
    keys: STATUS_KEYS,
  });

const getDevicesDeployment = (ids) =>
  getDevicesCollection({
    collection: COLLECTIONS.DEVICE_DEPLOYMENT,
    ids,
    keys: DEPLOYMENT_KEYS,
  });

const getDevicesRemoteInfo = (ids) =>
  Promise.all(
    ids.map(
      (id) =>
        getDocument({
          // for each id get the remote info
          collection: `${COLLECTIONS.DEVICE}/${id}/${SUBCOLLECTIONS.EXTRA}`,
          doc: DOCUMENTS.REMOTE_INFO,
        }).then((doc) => [id, doc]) // convert to key + data
    )
  ).then((res) => Object.fromEntries(res)); // convert array to map

const getProjectDevicesId = (projectId) => {
  const collection = COLLECTIONS.PROJECT_DEVICES;
  const wheres = [[KEYS.PROJECT_ID, "==", projectId]];
  return queryDocuments({ collection, wheres }).then((devices) =>
    devices.map((d) => d[KEYS.DEVICE_ID])
  );
};

const getStoresAddress = async (storeDocIds) => {
  const addresses = await Promise.all(
    storeDocIds.map((id) =>
      getDocument({
        collection: COLLECTIONS.DEVICE_STORE,
        doc: id,
      }).then((storeInfo) => storeInfo?.[KEYS.DEVICE_ADDRESS])
    )
  );
  return Object.fromEntries(storeDocIds.map((id, i) => [id, addresses[i]]));
};

const addAddressToDevices = (infos, addresses) => {
  Object.keys(infos).forEach((id) => {
    infos[id][KEYS.DEVICE_ADDRESS] =
      addresses[infos[id][KEYS.DEVICE_STORE_DOC_ID]];
  });
};

const addLinkToDevices = (infos) => {
  Object.keys(infos).forEach((id) => {
    if (infos[id][KEYS.DEVICE_LINK_ID]) {
      infos[id][KEYS.DEVICE_LINK] = `${window.location.protocol}//${window.location.host}/media/${infos[id][KEYS.DEVICE_LINK_ID]}`
    }
  });
};

const getProjectAllDevices = async (projectId, includeRemoteInfo = false) => {
  const ids = await getProjectDevicesId(projectId);
  const promises = [
    getDevicesInfo(ids),
    getDevicesStatus(ids),
    getDevicesDeployment(ids),
    includeRemoteInfo && getDevicesRemoteInfo(ids),
  ];
  const res = await Promise.all(promises);
  // convert infos to storeDocIds
  const storeDocIds = [
    ...new Set(
      Object.values(res[0])
        .map((info) => info[KEYS.DEVICE_STORE_DOC_ID])
        .filter((id) => !!id)
    ),
  ];
  const storesAddress = await getStoresAddress(storeDocIds);
  addAddressToDevices(res[0], storesAddress);
  addLinkToDevices(res[0]);
  return ids.reduce(
    (total, id) => {
      console.log("res[0][id] : ", res[0][id]);
      console.log("res[1][id] : ", res[1][id]);
      console.log("res[2][id] : ", res[2][id]);
      return ({
        ...total,
        [id]: {
          ...res[0][id], // info
          ...res[1][id], // status
          ...res[2][id], // deployment
          ...(includeRemoteInfo ? res[3][id] : []), // remote
        },
      })
    },
    {}
  );
};

const getProjectDevices = (
  projectId,
  decommissioned = false,
  includeRemoteInfo = false
) =>
  getProjectAllDevices(projectId, includeRemoteInfo).then((devices) =>
    Object.fromEntries(
      Object.entries(devices).filter(
        ([, d]) => !!d[KEYS.DEVICE_DECOMMISSIONED] === decommissioned
      )
    )
  );

export const exportProjectDevices = async (
  projectId,
  projectTitle,
  live = true,
  isAdmin = false
) => {
  const devices = await getProjectDevices(projectId, !live, isAdmin);
  // convert map to list
  const devicesArray = Object.entries(devices).map(([, d]) => d);

  console.debug("exportProjectDevices", devicesArray);

  const csv = convertArrayToCsv(devicesArray, EXPORT_HEADERS(isAdmin));
  console.debug(`Exporting ${devicesArray.length} devices...`);

  const title = projectTitle.replace(/[^\w]+/g, "_");
  const liveOrDecommission = live ? "Live" : "Decommission";
  const filename = `MyProjects-${title}-${liveOrDecommission}-Devices.csv`;
  downloadBlob(filename, csv, "csv");
};

/**
 * Device info/profile
 */

// return device store info as an object
// own keys are defined here instead of directly using the key from firestore
//
// const object = {
//   storeId,
//   country,
//   postcode,
//   retailer
// }

const useInfo = ({ deviceId, collection, keys, path }) => {
  const dispatch = useDispatch();
  const info = useSelectorWithPath(path);
  const keysString = keys.sort((a, b) => a - b).join("/");
  useEffect(() => {
    if (!deviceId || !collection) return;
    const k = keysString.split("/");
    const unsub = subscribeDocument({
      collection,
      doc: deviceId,
      dispatch,
      path,
      constructPath,
      constructData: ({ id, data }) => ({
        [id]: extractKeyValuesFromDict(data, k),
      }),
    });
    return () => {
      unsubscribeDocument({
        collection,
        doc: deviceId,
        path,
        dispatch,
      });
      unsub();
    };
  }, [deviceId, collection, keysString, dispatch, path]);
  if (!deviceId) return;
  if (!info) return info;
  return deviceId in info ? info[deviceId] : null;
};

const useDeviceCompleteStatus = (deviceId) =>
  useDocument({
    collection: COLLECTIONS.DEVICE_STATUS,
    doc: deviceId,
  });



const useDeviceInfo = (deviceId) =>
  useInfo({
    deviceId,
    collection: COLLECTIONS.DEVICE,
    keys: INFO_KEYS,
    path: "currentDevice/info",
  });

const useDeviceStatusInfo = (deviceId) =>
  useInfo({
    deviceId,
    collection: COLLECTIONS.DEVICE_STATUS,
    keys: STATUS_KEYS,
    path: "currentDevice/status",
  });

const useDeviceDeploymentInfo = (deviceId) =>
  useInfo({
    deviceId,
    collection: COLLECTIONS.DEVICE_DEPLOYMENT,
    keys: DEPLOYMENT_KEYS,
    path: "currentDevice/deployment",
  });

export const useDeviceStoreInfo = (deviceId) => {
  const info = useDeviceInfo(deviceId);
  return (
    info && {
      storeId: info[KEYS.DEVICE_STORE_ID],
      country: info[KEYS.DEVICE_COUNTRY],
      postcode: info[KEYS.DEVICE_POSTCODE],
      retailer: info[KEYS.DEVICE_RETAILER_NAME],
    }
  );
};

export const useDeviceTags = (deviceId) => {
  const isList = Array.isArray(deviceId);
  const info = useDeviceInfo(!isList && deviceId);
  return (info && info[KEYS.DEVICE_TAGS]) || [];
};

export const useDeviceDecommissionInfo = (deviceId) => {
  const info = useInfo({
    deviceId,
    collection: COLLECTIONS.DEVICE,
    keys: DECOMMISSION_KEYS,
    path: `currentDevice/decomissionInfo`, // we only read one device at at time so use an universal storeAs
  });
  if (!info) return info;
  return {
    decommissioned: info[KEYS.DEVICE_DECOMMISSIONED],
    at: info[KEYS.DEVICE_DECOMMISSIONED_AT],
    by: info[KEYS.DEVICE_DECOMMISSIONED_BY],
  };
};

export const useDeviceLive = (deviceId) => {
  const info = useDeviceInfo(deviceId);
  // info = undefined -> loading
  // info.decommissioned = undefined -> false
  return info && !info.decommissioned;
};

/**
 * a device's project id can be tricky to identify
 * querying the "projects_and_devices" won't work since we need to check if the user has the permission in the project
 * either we iterate through all the projects the current user is in, then again iterate devices in every project, not ideal.
 * alternatively we can rely on "projectId" being updated inside the devices collections
 * latest project id could be located at:
 * 1) /device_status/{deviceId}.projectId, or
 * 2) /device_status/{deviceId}.project.id, or
 * 3) /devices/{deviceId}.projectId, or
 * 4) /devices/{deviceId}.projects, or
 *
 * 3) is the easiest location and should be up-to-date after cloud-functions since 2021/07/16
 */
export const useDeviceProjectId = (deviceId) => {
  // we don't want to useDeviceInfo() to mess up with global "currentDevice"
  const dispatch = useDispatch();
  const collection = COLLECTIONS.DEVICE;
  const path = "devices/project_id";
  const info = useSelectorWithPath(path);
  useEffect(() => {
    if (!deviceId) return;
    const unsub = subscribeDocument({
      collection,
      doc: deviceId,
      dispatch,
      path,
      constructPath,
      constructData: ({ id, data }) => ({
        [id]: data?.[KEYS.PROJECT_ID],
      }),
    });
    return () => {
      unsubscribeDocument({
        collection,
        doc: deviceId,
        path,
        dispatch,
      });
      unsub();
    };
  }, [deviceId, collection, dispatch, path]);
  if (!deviceId) return;
  if (!info) return info;
  return deviceId in info ? info[deviceId] : null;
};

export const useDeviceCompliant = (deviceId) => {
  const info = useDeviceDeploymentInfo(deviceId);
  return info && info?.state === DEPLOYMENT_STATE.COMPLIANT;
};

export const useDeviceOnline = (deviceId, platform) => {
  const status = useDeviceCompleteStatus(deviceId);
  if (!status) return status;
  return status && status?.updatedAt && isOnline(new Date(status.updatedAt), platform);
};

export const useDeviceLastComplianceCheckTimestamp = (deviceId) => {
  const info = useDeviceDeploymentInfo(deviceId);
  let moment = info?.moment;
  // detect and add ms
  if (moment && moment.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/g))
    moment += ".000Z";
  return moment && Date.parse(moment);
};

export const useDeviceLastOnlineTimestamp = (deviceId) => {
  const info = useDeviceStatusInfo(deviceId);
  return info && info?.updatedAt && Date.parse(info.updatedAt);
};

/**
 * Device extra
 */

const useDeviceExtra = (deviceId, subdoc = DOCUMENTS.EXTRA_INFO) => {
  const collection = COLLECTIONS.DEVICE;
  const doc = deviceId;
  const subcollection = SUBCOLLECTIONS.EXTRA;
  return useSubCollectionDocument({
    collection,
    doc,
    subcollection,
    subdoc,
  });
};

export const useDeviceNote = (deviceId) => {
  const extra = useDeviceExtra(deviceId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.DEVICE_NOTE in extra ? extra[KEYS.DEVICE_NOTE] : "";
};

export const useDeviceSim = (deviceId) => {
  const extra = useDeviceExtra(deviceId);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.DEVICE_SIM in extra ? extra[KEYS.DEVICE_SIM] : "";
};

export const useDeviceConnectedDevices = (deviceId) => {
  const extra = useDeviceExtra(deviceId, DOCUMENTS.REMOTE_INFO);
  // undefined = loading
  if (isUndefined(extra)) return extra;
  return extra && KEYS.DEVICE_CONNECTED_DEVICES in extra
    ? extra[KEYS.DEVICE_CONNECTED_DEVICES]
    : null;
};

export const subscribeDeviceExtraInfo = ({ deviceId, onData, onError }) =>
  subscribeDocument({
    collection: `${COLLECTIONS.DEVICE}/${deviceId}/${SUBCOLLECTIONS.EXTRA}`,
    doc: DOCUMENTS.EXTRA_INFO,
    onData,
    onError,
  });

/**
 * Device requests to server API
 */

export const requestOnline = ({ projectId, deviceId }) =>
  requestHeartbeat({ projectId, deviceId });

/**
 * project-specific
 */

const useDevicesCount = ({ collection, where }) => {
  const docs = useCollectionNoStore({
    collection,
    where,
    // we just want the count so simply set document to true
    processData: (id, data) => true,
  });
  if (isUndefined(docs)) return docs;
  return docs ? Object.values(docs).filter((d) => d).length : 0;
};

// get total number of devices in this project, from "project_devices"
// "project_devices" document has minimal keys therefore useCollection to store them all is fine
export const useTotalDevicesCount = (projectId) => {
  const collection = projectId && COLLECTIONS.PROJECT_DEVICES;
  const where = [[KEYS.PROJECT_ID, "==", projectId]];
  const path = "devices/count/total";
  return useDevicesCount({ collection, where, path });
};

// get number of decommissioned devices in this project, from "devices"
export const useDecommissionedDevicesCount = (projectId) => {
  const collection = projectId && COLLECTIONS.DEVICE;
  const where = [
    [KEYS.PROJECT_ID, "==", projectId],
    [KEYS.DEVICE_DECOMMISSIONED, "==", true],
  ];
  const path = "devices/count/decomm";
  return useDevicesCount({ collection, where, path });
};

// number of live devices = total - decommissioned
export const useLiveDevicesCount = (projectId) => {
  const total = useTotalDevicesCount(projectId);
  const decomm = useDecommissionedDevicesCount(projectId);
  if (isUndefined(total)) return total;
  if (isUndefined(decomm)) return decomm;
  return total - decomm;
};

// get number of decommissioned and compliant devices in this project, from "device_deployment"
const useDecommissionedCompliantDevicesCount = (projectId) => {
  const collection = projectId && COLLECTIONS.DEVICE_DEPLOYMENT;
  const where = [
    [KEYS.PROJECT_ID, "==", projectId],
    [KEYS.DEVICE_DEPLOYMENT, "==", DEPLOYMENT_STATE.COMPLIANT],
    [KEYS.DEVICE_DECOMMISSIONED, "==", true],
  ];
  const path = "devices/count/compDecomm";
  return useDevicesCount({ collection, where, path });
};

// number of compliant devices = all compliant - decomissioned comliant, from "device_deployment"
export const useCompliantDevicesCount = (projectId) => {
  const collection = projectId && COLLECTIONS.DEVICE_DEPLOYMENT;
  const where = [
    [KEYS.PROJECT_ID, "==", projectId],
    [KEYS.DEVICE_DEPLOYMENT, "==", DEPLOYMENT_STATE.COMPLIANT],
  ];
  const path = "devices/count/comp";
  const total = useDevicesCount({ collection, where, path });
  const decomm = useDecommissionedCompliantDevicesCount(projectId);
  if (isUndefined(total)) return total;
  if (isUndefined(decomm)) return decomm;
  return total - decomm;
};

// get number of decommissioned and online devices in this project, from "device_status"
const useDecommissionedOnlineDevicesCount = (projectId) => {
  const lastDate = getLastOnlineDate().toISOString();
  const collection = projectId && COLLECTIONS.DEVICE_STATUS;
  const where = [
    [KEYS.PROJECT_ID, "==", projectId],
    [KEYS.DEVICE_LAST_ONLINE, ">", lastDate],
    [KEYS.DEVICE_DECOMMISSIONED, "==", true],
  ];
  const path = "devices/count/onlineDecomm";
  return useDevicesCount({ collection, where, path });
};

export const useOnlineDeviceByPlatformCount = (projectId) => {
  // service data
  let devices = useProjectDevices({ projectId, params: {}});
  if(!devices)return 0;
  let onlineDevices = devices.filter(device => !device.decommissioned && isOnline(new Date(device.updatedAt), device.platform));
  return onlineDevices.length;
}

// number of online devices = all online - decommissioned online, from "device_status"
export const useOnlineDevicesCount = (projectId) => {
  const lastDate = getLastOnlineDate().toISOString();
  const collection = projectId && COLLECTIONS.DEVICE_STATUS;
  const where = [
    [KEYS.PROJECT_ID, "==", projectId],
    [KEYS.DEVICE_LAST_ONLINE, ">", lastDate],
  ];
  const path = "devices/count/online";
  const total = useDevicesCount({ collection, where, path });
  const decomm = useDecommissionedOnlineDevicesCount(projectId);
  if (isUndefined(total)) return total;
  if (isUndefined(decomm)) return decomm;
  return total - decomm;
};

const constructPath = ({ id, path }) => (id ? `${path}/${id}` : path);

// const params = {
//   limit: 40,
//   startAt: 0,
//   // orderBy: "name",
//   orderBy: "modified_date",
//   orderDesc: false,
//   filter: "abc",
//   live: true,
// };
export const useProjectDevices = ({ projectId, params }) => {
  const ids = useProjectDevicesId(projectId);

  const getConfig = (collection, keys) => ({
    collection: projectId && collection,
    where: [[KEYS.PROJECT_ID, "==", projectId]],
    processData: (id, data) => extractKeyValuesFromDict(data, keys),
  });

  const docs = [
    useCollectionNoStore(getConfig(COLLECTIONS.DEVICE, LIST_INFO_KEYS)),
    useCollectionNoStore(
      getConfig(COLLECTIONS.DEVICE_STATUS, LIST_STATUS_KEYS)
    ),
    useCollectionNoStore(
      getConfig(COLLECTIONS.DEVICE_DEPLOYMENT, lIST_DEPLOYMENT_KEYS)
    ),
  ];

  if (isUndefined(ids)) return undefined;

  if (!ids) return [];

  const hasLive = "live" in params;
  const hasStartAt = "startAt" in params;
  const hasLimit = "limit" in params;
  const hasDesc = "orderDesc" in params;
  const live = params?.live;
  const filter = params?.filter;
  const orderBy = params?.orderBy;
  const desc = params?.orderDesc;
  const startAt = params?.startAt;
  const limit = params?.limit;

  // merge data
  let res = ids.map((id) => {
    return {
      [KEYS.DEVICE_ID]: id,
      ...(docs[0]?.[id] || {}),
      ...(docs[1]?.[id] || {}),
      ...(docs[2]?.[id] || {}),
    };
  });

  // filter
  if (filter)
    res = res.filter((device) => deviceContainsFilter({ device, filter }));

  // live / decommission
  if (hasLive) res = res.filter((device) => isDeviceLive(device) === live);

  // sort
  if (orderBy && hasDesc) res = res.sort(deviceSortFunction(orderBy, desc));

  // limit
  if (hasLimit && hasStartAt) res = res.slice(startAt, limit);

  return res;
};

/**
 * utils
 */

const deviceContainsFilter = ({ device, filter }) => {
  // nothing to check
  if (!device || !filter) return true;

  if (filter.length === 0) return true;

  const filterLower = filter.toLowerCase();
  const idLower =
    device[KEYS.DEVICE_ID] && device[KEYS.DEVICE_ID].toLowerCase();
  const storeIdLower =
    device[KEYS.DEVICE_STORE_ID] && device[KEYS.DEVICE_STORE_ID].toLowerCase();
  const stageLower =
    device[KEYS.DEVICE_STAGE] && device[KEYS.DEVICE_STAGE].toLowerCase();
  return (
    (idLower && idLower.includes(filterLower)) ||
    (storeIdLower && storeIdLower.includes(filterLower)) ||
    (stageLower && stageLower.includes(filterLower))
  );
};

const deviceSortFunction = (orderBy, desc) => (a, b) => {
  const x = desc ? b[orderBy] : a[orderBy];
  const y = desc ? a[orderBy] : b[orderBy];
  if (isUndefined(y)) return isUndefined(x) ? 0 : 1;
  else if (isUndefined(x)) return -1;
  if (typeof x === "string" && typeof x === "string") return x.localeCompare(y);
  else return x - y;
};

export const isDeviceLive = (device) =>
  device && !device[KEYS.DEVICE_DECOMMISSIONED];

/**
 * cloud control
 */

export const useDeviceCommandRequest = (deviceId) =>
  useDocument({
    collection: "device_cc_requests",
    doc: deviceId && `${deviceId}_command`,
  });

export const useDeviceCommandResponse = (deviceId) =>
  useDocument({
    collection: "device_cc_responses",
    doc: deviceId && `${deviceId}_command`,
  });

export const useDeviceOnlineRequest = (deviceId) =>
  useDocument({
    collection: "device_cc_requests",
    doc: deviceId && `${deviceId}_online`,
  });

export const useDeviceOnlineResponse = (deviceId) =>
  useDocument({
    collection: "device_cc_responses",
    doc: deviceId && `${deviceId}_online`,
  });

export const useDeviceDownloadReady = (deviceId) => {
  const req = useDeviceCommandRequest(deviceId);
  const res = useDeviceCommandResponse(deviceId);
  return (
    req?.action === "get" &&
    req?.status === "completed" &&
    res?.timestamp > req?.timestamp &&
    res?.error === ""
  );
};

const prefix = process.env.REACT_APP_DEMO_MODE
  ? "https://storage.googleapis.com/myplayertest.appspot.com/"
  : "https://storage.googleapis.com/myplayerbase.appspot.com/";

export const useDeviceDownloadPath = (deviceId) => {
  const req = useDeviceCommandRequest(deviceId);
  if (
    req?.action !== "get" ||
    req?.status !== "completed" ||
    !req?.payload?.key
  )
    return null;
  return `${prefix}devices/${deviceId}/${req?.payload?.key}?t=${Date.now()}`;
};

export const useDevicePlatform = (deviceId) => {
  const [platform, setPlatform] = useState();
  const doc = deviceId && `${deviceId}`;
  const deviceDoc = useDocument({
    collection: "devices",
    doc,
  });
  const devicePlatform = deviceDoc?.platform;

  useEffect(() => {
    setPlatform(deviceId && devicePlatform ? devicePlatform : "android");
  }, [deviceId, devicePlatform]);
  return platform;
};

export const useDeviceScreen = (deviceId) => {
  const [screen, setScreen] = useState();
  const doc = deviceId && `${deviceId}_screencap`;
  const req = useDocument({
    collection: "device_cc_requests",
    doc,
  });
  const resp = useDocument({
    collection: "device_cc_responses",
    doc,
  });
  const size = req?.payload?.size;
  const ts = resp?.timestamp;
  useEffect(() => {
    if (!deviceId || !ts) {
      // clear screen if no deviceId or no screencap response
      setScreen(undefined);
      return;
    }
    const screencapPath = `devices/${deviceId}/screencap`;
    isPathExist(screencapPath).then((exist) => {
      if (exist)
        setScreen({ size, path: prefix + screencapPath + `?t=${Date.now()}` });
    });
  }, [deviceId, ts, size]);
  return screen;
};

export const useDeviceScreenRefreshing = (deviceId) => {
  const [refreshing, setRefreshing] = useState();
  const reqScreencap = useDocument({
    collection: "device_cc_requests",
    doc: deviceId && `${deviceId}_screencap`,
  });
  const reqCommand = useDocument({
    collection: "device_cc_requests",
    doc: deviceId && `${deviceId}_command`,
  });
  const statusScreencap = reqScreencap?.status;
  const statusCommand = reqCommand?.status;
  useEffect(() => {
    if (!deviceId) return;
    setRefreshing(statusScreencap === "pending" || statusCommand === "pending");
  }, [deviceId, statusScreencap, statusCommand]);
  return refreshing;
};

const remoteCommand = ({ projectId, deviceId, command }) =>
  ccCommand({
    projectId,
    deviceId,
    command,
  }).catch((err) => {
    console.error(err);
  });

export const remoteRefresh = ({ projectId, deviceId }) =>
  ccScreencap({ projectId, deviceId });

export const remoteHome = ({ projectId, deviceId }) =>
  remoteCommand({
    projectId,
    deviceId,
    command:
      "am start -a android.intent.action.MAIN -c android.intent.category.HOME",
  });

export const remoteBack = ({ projectId, deviceId }) =>
  remoteCommand({
    projectId,
    deviceId,
    command: "input keyevent KEYCODE_BACK",
  });

export const remoteRecent = ({ projectId, deviceId }) =>
  remoteCommand({
    projectId,
    deviceId,
    command: "input keyevent KEYCODE_APP_SWITCH",
  });

export const remoteClick = ({ projectId, deviceId, x, y }) =>
  remoteCommand({
    projectId,
    deviceId,
    command: `input tap ${x} ${y}`,
  });

export const remoteSwipe = ({ projectId, deviceId, x1, y1, x2, y2, ms }) =>
  ccCommand({
    projectId,
    deviceId,
    command: `input swipe ${x1} ${y1} ${x2} ${y2} ${ms}`,
  });

export const remoteKeyboardInput = ({ projectId, deviceId, text }) =>
  ccCommand({
    projectId,
    deviceId,
    command: `input keyboard text '${text}'`,
  });

export const remoteBackspace = ({ projectId, deviceId }) =>
  remoteCommand({
    projectId,
    deviceId,
    command: "input keyevent 67",
  });

/**
 * heartbeats
 */

const millisecondToDay = 1000 * 60 * 60 * 24;

const isDeviceOnlineBetween = ({ deviceId, timestampStart, timestampEnd }) =>
  queryCollection({
    collection: deviceId && COLLECTIONS.DEVICE_HEARTBEAT,
    where: [
      [KEYS.DEVICE_ID, "==", deviceId],
      [KEYS.DEVICE_TIMESTAMP, ">=", timestampStart / 1000],
      [KEYS.DEVICE_TIMESTAMP, "<", timestampEnd / 1000],
    ],
    orderBy: [[KEYS.DEVICE_TIMESTAMP, "desc"]],
    limit: 1,
  }).then((res) => [timestampStart, Object.keys(res).length === 1]);

const useDeviceOnlineToday = (deviceId) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const heartbeatTodayLast = useCollection({
    collection: deviceId && COLLECTIONS.DEVICE_HEARTBEAT,
    where: [
      [KEYS.DEVICE_ID, "==", deviceId],
      [KEYS.DEVICE_TIMESTAMP, ">=", today.getTime() / 1000],
    ],
    orderBy: [[KEYS.DEVICE_TIMESTAMP, "desc"]],
    limit: 1,
  });
  if (isUndefined(heartbeatTodayLast)) return heartbeatTodayLast;
  return !!(heartbeatTodayLast && Object.keys(heartbeatTodayLast).length === 1);
};

export const useDeviceOnlineDays = ({ deviceId, days = 7 }) => {
  const [onlineDays, setOnlineDays] = useState();
  // subscribe to latest heartbeat for reactive update
  const onlineToday = useDeviceOnlineToday(deviceId);
  useEffect(() => {
    if (days < 0) return;
    Promise.all(
      Array(days + 1)
        .fill(null)
        .map((d, i) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const start = today.getTime() - i * millisecondToDay;
          const end = start + millisecondToDay;
          return isDeviceOnlineBetween({
            deviceId,
            timestampStart: start,
            timestampEnd: end,
          });
        })
    ).then((res) => {
      setOnlineDays(res);
    });
  }, [deviceId, days, onlineToday]);
  return onlineDays;
};

export const getMediaLink = async (deviceID) => {
  const collection = COLLECTIONS.DEVICE_MEDIA_LINKS;
  const where = [["deviceId", "==", deviceID]];
  const links = await getCollectionGroup({ collection, where });
  const link = Object.values(links)[0];
  return { ...link, url: getLinkUrl(link) };
};

export const useMediaLink = (linkId) => {
  const [link, setLink] = useState();
  useEffect(() => {
    getMediaLink(linkId).then((res) => {
      setLink(res);
    });
  }, [linkId]);

  return link;
};
