import React, {useCallback, useEffect, useState} from "react";

import {MEDIA_LINKS_SORT_OPTION} from "../services/MediaService";
import {
  useUserProjectPermissions,
  useTotalProjectActiveSharedMediaLink,
} from "../services/ProjectService";
import {updateUrl} from "../services/UiService";
import * as Permissions from "../utils/permissionUtils";
import {useSystemAdmin} from "../services/UserService";

import PullUpPanel from "./PullUpPanel";
import EditAppsTab from "./tabs/EditAppsTab";
import EditContentTab from "./tabs/EditContentTab";
import EditShowroomTab from "./tabs/EditShowroomTab";
import EditTasksTab from "./tabs/EditTasksTab";

import {getProjectMediaEditTabRoute} from "../route";
import {ProjectStrings} from "../strings";

const URLS = ["content", "apps", "showroom", "tasks"];

const EditMediaPanel = ({projectId, userId, open, onClose, tabName}) => {
  const totalLinksCount = useTotalProjectActiveSharedMediaLink({projectId});
  const [currentTab, setCurrentTab] = useState(
    Math.max(0, URLS.indexOf(tabName))
  );
  const isAdmin = useSystemAdmin();

  // search and sort for showroom
  const [showroomSearchText, setShowroomSearchText] = useState("");
  const [sortOption, setSortOption] = useState(MEDIA_LINKS_SORT_OPTION[0]);
  const [sortDesc, setSortDesc] = useState(true);
  const [useSearch, setUseSearch] = useState(false);

  // permissions control
  const permissions = useUserProjectPermissions({userId, projectId});
  const canReadShowroom = Permissions.canReadShowroom(permissions);
  const canWriteContent = Permissions.canWriteContent(permissions);
  const showSearchAndSort = canReadShowroom && totalLinksCount > 0;

  // align with URLS above
  const tabs = [
    {
      label: "Content",
      render: (halfOpened, active) => <EditContentTab projectId={projectId} />,
    },
    {
      label: "Apps",
      render: (halfOpened, active) => (
        <EditAppsTab projectId={projectId} canRead={canWriteContent} />
      ),
    },
    {
      label: "Showroom",
      render: (halfOpened, active) => (
        <EditShowroomTab
          projectId={projectId}
          canRead={canReadShowroom}
          showroomSearchText={showroomSearchText}
          sortOption={sortOption}
          sortDesc={sortDesc}
        />
      ),
    },
  ];
  if (isAdmin) {
    tabs.push({
      label: "Tasks",
      render: (halfOpened, active) => (
        <EditTasksTab projectId={projectId} canRead={true} />
      ),
    });
  }

  const onClickTab = useCallback(
    (oldTab, newTab) => {
      setCurrentTab(newTab);
      setUseSearch(tabs[newTab]?.label === "Showroom");
      updateUrl(getProjectMediaEditTabRoute(projectId, URLS[newTab]));
    },
    [projectId, tabs]
  );

  useEffect(() => {
    if (open) {
      // on open
      if (tabName) {
        // if tabName is given by parent, set it as current tab
        const tab = Math.max(0, URLS.indexOf(tabName));
        setCurrentTab(tab);
        onClickTab(-1, tab);
      } else {
        // otherwise use currentTab state
        onClickTab(-1, currentTab);
      }
    } else {
      // reset tab on close
      setCurrentTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSearch = (value) => {
    setShowroomSearchText(value);
  };

  const sort = MEDIA_LINKS_SORT_OPTION.map((opt, index) => ({
    label: ProjectStrings[opt],
    onClick: () => {
      if (sortOption === opt) {
        setSortDesc(!sortDesc);
      }
      setSortOption(opt);
    },
    selected: opt === sortOption,
    desc: sortDesc,
  }));

  const config = {
    title: ProjectStrings.MEDIA_EDIT_TITLE,
    onClose,
    tabs,
    currentTab,
    onClickTab,
    ...(useSearch && showSearchAndSort && {onSearch}),
    ...(useSearch && showSearchAndSort && {sort}),
  };

  return <PullUpPanel open={open} config={config} />;
};

export default EditMediaPanel;
